import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemComponent } from './system.component';

const routes: Routes = [
  { path: '', component: SystemComponent, children: [
    { path: 'language', loadChildren: () => import('./language/language.module').then(m => m.LanguageModule) },
    { path: 'code-system', loadChildren: () => import('./code-system/code-system.module').then(m => m.CodeSystemModule) },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule { }
